/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Hero,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import { Layout } from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import { ColorBar, WaysToGiveSideNav } from "../components/elements"

const WaysToGive = ({ data, pageContext }) => {
  const page = data.datoCmsWaysGive
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Box>
        <Hero>
          <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
            <Container
              sx={{ position: "relative", px: "1em", py: [5, 6], zIndex: 10 }}
            >
              <Styled.h1>{page.title}</Styled.h1>
              <Breadcrumb
                crumbs={crumbs}
                crumbSeparator=" / "
                crumbLabel={page.title}
              />
            </Container>
            <Overlay sx={{ opacity: 1 }} />
          </BackgroundImage>
          <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
        </Hero>

        <Container sx={{ px: "1em", py: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Box
              as="main"
              sx={{
                flexGrow: 99999,
                flexBasis: 0,
                minWidth: 320,
              }}
            >
              {/* Content */}
              <Box as="section" id="content">
                <Flex flexWrap="wrap" justifyContent="center">
                  <Box sx={{ width: ["full"], pr: [0, 0, 4] }}>
                    <Text
                      sx={{ width: ["full"] }}
                      dangerouslySetInnerHTML={{
                        __html: page.contentNode.childMarkdownRemark.html,
                      }}
                    />
                  </Box>
                </Flex>
              </Box>
            </Box>
            <WaysToGiveSideNav />
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default WaysToGive

export const query = graphql`
  query waysToGiveQuery($slug: String!) {
    datoCmsWaysGive(slug: { eq: $slug }) {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      id
      title
      slug
      meta {
        status
      }
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        tags
      }
    }
  }
`
